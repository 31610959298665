// 境外舆情的接口
import request from '@/api/request.js'
// 本地
export function abroadList(data) {
    return request({
        url: 'api/msgquery/data/list',
        method: 'post',
        data: data
    })
}
// 全国
export function countryList(data) {
    return request({
        url: 'api/msgquery/data/countryList',
        method: 'post',
        data: data
    })
}
// 境外本地详情
export function getAbroadInfo(data) {
    return request({
        url: 'api/msgquery/data/getInfo',
        method: 'post',
        data: data
    })
}
// 境外全部详情
export function newgetInfo(data) {
    return request({
        url: 'api/msgquery/data/newgetInfo',
        method: 'post',
        data: data
    })
}
export function editLabel(data) {
    return request({
        url: 'api/msgquery/data/edit',
        method: 'post',
        data: data
    })
}
// 顶部筛选条件
export function topList(data) { 
    return request({
        url: 'api/msgquery/data/topList',
        method: 'post',
        data: data
    })
}
// 删除标签
export function deleteLabel(data) { 
    return request({
        url: 'api/msgquery/data/deleteLabel',
        method: 'post',
        data: data
    })
}
// 刪除数据
export function deleteList(data) { 
    return request({
        url: 'api/msgquery/data/remove',
        method: 'post',
        data: data
    })
}
export function likeList(data) {
    return request({
        url: 'api/msgquery/data/likeList',
        method: 'post',
        data: data
    })
}
//翻译功能
export function translate(data) {
    return request({
        url: '/overseasTra',
        method: 'post',
        data: data
    })
}

//获取翻译语种
export function message() {
    return request({
        url: "message/" + 3,
        method: 'get'
    })
}