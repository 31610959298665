<!-- eslint-disable -->
<template>
  <div class="information abroadInfo">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="pushClick2">
            <img src="../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item v-if="type == 'setting'" :to="{ path: '/systemSettings/index' }">系统设置</el-breadcrumb-item>
      <el-breadcrumb-item v-else-if="type == 'yujing' || types == '首页监测'"
        :to="{ path: '/index' }">预警信息</el-breadcrumb-item>

      <el-breadcrumb-item v-else :to="{ path: '/sensitiveMessage/index' }">境外敏感信息</el-breadcrumb-item>

      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div v-loading="loading" style="min-height: 495px">
      <div v-if="textData.isDel == 1" class="information-box">
        <div class="delete-wrap">
          <div class="delete-div">
            <div class="delete-img">
              <img src="../assets/img/views/deleteWrap.png" alt />
              <div class="delete-x">X</div>
            </div>
            <p>当前数据已删除</p>
          </div>
        </div>
      </div>
      <!-- 外面的大盒子 -->
      <div v-if="textData.isDel != 1" class="information-box">
        <div class="informaton-main">
          <div class="main-top">
            <div class="main-top-left"></div>
            <!-- 中间部分 -->
            <div class="main-top-center">
              <div class="main-top-center-box">
                <div v-if="textData.isEarlyWarn == 1" class="yujing">已预警</div>
                <div class="yujing-box">
                  <p class="center-title" v-html="textData.title ? textData.title : '无'"></p>
                  <p v-if="textData.titleF" style="display: inline-block"
                    v-html="textData.titleF ? textData.titleF : ''" class="center-title"></p>
                </div>
              </div>
              <div class="caozuo-center">
                <div v-if="activeName == 'second'" class="type english">{{ textData.languageType ? textData.languageType
            :
            "无" }}</div>
                <div v-if="activeName == 'first'" :class="['type', textData.sensitives == 0 ? 'english' : 'red']">{{
            textData.sensitives == 0 ? '非敏感' : '敏感' }}</div>
                <div class="type website">{{ textData.mediaType ? textData.mediaType : "无" }}</div>
                <a :href="textData.website" target="_blank" class="original original-color">
                  <span>
                    <img src="../assets/img/views/wenzhang.png" alt />
                  </span>
                  <span>查看原文</span>
                </a>
                <span v-if="types != '首页监测' && activeName == 'first' && type != 'setting'"
                  class="original comment-weidu">
                  <span>
                    <img v-if="textData.isRead == '1'" src="../assets/img/views/greenmessage.png" alt />
                    <img v-else src="../assets/img/views/redmessage.png" alt />
                  </span>
                  <span :class="[textData.isRead == 1 ? 'yidu' : ' ']">{{ textData.isRead == "1" ? "已读" : "未读" }}</span>
                </span>
                <div class="center-timer">
                  发布时间:
                  <span>
                    {{
            textData.releaseTime ? textData.releaseTime : "无"
          }}
                  </span>
                </div>
              </div>
              <div class="operation-box">
                <!-- 设置标签 -->
                <el-select v-if="activeName == 'first' && activeNames != 'six'" class="select-biaoqian"
                  v-model="textData.setLabel" placeholder="设置标签" size="small" @change="changeLabel(textData)">
                  <el-option v-for="(item, index) in biaoqianList" :key="index" :label="item.labelName"
                    :value="item.labelId"></el-option>
                </el-select>
                <!-- 复制 -->
                <div class="operation-copy my-caozuo" @click="copy(textData)">
                  <span>
                    <span>
                      <img src="../assets/img/views/fuzhi.png" alt />
                    </span>
                  </span>
                  <span>复制全部</span>
                </div>
                <div @click="quickCopy(textData)" class="my-caozuo">
                  <span style="margin:0;color:black;font-size:16px" class="el-icon-link"></span>
                  复制链接和标题
                </div>
                <span style="font-size: 14px;margin-left: 40px;cursor: pointer;" @click="translateYes(textData)">
                  <svg style="vertical-align: bottom;" t="1672899721329" class="icon" viewBox="0 0 1024 1024"
                    version="1.1" p-id="5024" width="18" height="18">
                    <path
                      d="M608 416h288c35.36 0 64 28.48 64 64v416c0 35.36-28.48 64-64 64H480c-35.36 0-64-28.48-64-64v-288H128c-35.36 0-64-28.48-64-64V128c0-35.36 28.48-64 64-64h416c35.36 0 64 28.48 64 64v288z m0 64v64c0 35.36-28.48 64-64 64h-64v256.032c0 17.664 14.304 31.968 31.968 31.968H864a31.968 31.968 0 0 0 31.968-31.968V512a31.968 31.968 0 0 0-31.968-31.968H608zM128 159.968V512c0 17.664 14.304 31.968 31.968 31.968H512a31.968 31.968 0 0 0 31.968-31.968V160A31.968 31.968 0 0 0 512.032 128H160A31.968 31.968 0 0 0 128 159.968z m64 244.288V243.36h112.736V176h46.752c6.4 0.928 9.632 1.824 9.632 2.752a10.56 10.56 0 0 1-1.376 4.128c-2.752 7.328-4.128 16.032-4.128 26.112v34.368h119.648v156.768h-50.88v-20.64h-68.768v118.272H306.112v-118.272H238.752v24.768H192z m46.72-122.368v60.48h67.392V281.92H238.752z m185.664 60.48V281.92h-68.768v60.48h68.768z m203.84 488H576L668.128 576h64.64l89.344 254.4h-54.976l-19.264-53.664h-100.384l-19.232 53.632z m33.024-96.256h72.864l-34.368-108.608h-1.376l-37.12 108.608zM896 320h-64a128 128 0 0 0-128-128V128a192 192 0 0 1 192 192zM128 704h64a128 128 0 0 0 128 128v64a192 192 0 0 1-192-192z"
                      fill="#333333" p-id="5025"></path>
                  </svg>
                  翻译
                </span>
                <!-- 截图 -->
                <div :class="textData.disabled || textData.fastPic ? 'disabled' : ''" v-if="type != 'setting'"
                  class="operation-jietu my-caozuo" @click="screenShot(textData)">
                  <span>
                    <span>
                      <img src="../assets/img/views/tupian.png" alt />
                    </span>
                  </span>
                  <span>页面截图</span>
                </div>
                <!-- 收藏 -->
                <div v-if="activeName == 'first' && activeNames != 'six'" class="operation-shoucang my-caozuo"
                  @click="collect(textData)">
                  <span>
                    <span>
                      <img v-if="textData.isCollect == 1" src="../assets/img/views/yellowsc.png" alt />
                      <img v-else src="../assets/img/views/shoucang.png" alt />
                    </span>
                  </span>
                  <span :class="[textData.isCollect == 1 ? 'yishoucang' : ' ']">{{ textData.isCollect == 1 ? "已收藏" :
            "收藏" }}</span>
                </div>
                <!-- 数据上报 -->
                <div v-if="type == '境外' &&
            $store.state.user.isreport &&
            activeName == 'first'
            " class="operation-shoucang my-caozuo" @click="dataReport(textData)">
                  <span>
                    <span>
                      <img style="width: 16px; height: 16px" src="../assets/img/views/monitoringScheme/sahngchuan.png"
                        alt />
                    </span>
                  </span>
                  <span>
                    {{
            textData.isReport == 1 ? "已上报" : "数据上报"
          }}
                  </span>
                </div>
                <!-- 删除 -->
                <div v-if="type == '境外' && activeName == 'first'" class="operation-delete my-caozuo"
                  @click="deletes(textData)">
                  <span>
                    <span>
                      <img src="../assets/img/views/delete.png" alt />
                    </span>
                  </span>
                  <span>删除</span>
                </div>
              </div>
            </div>
            <div class="main-top-right"></div>
          </div>
          <div style="padding: 24px 210px 0 210px">
            <!-- 中间青色位置 -->
            <div class="main-center">
              <div class="main-center-left">
                <div class="left-source">
                  来源/作者:
                  <span v-html="textData.source ? textData.source : '无'"></span>
                  <span>/</span>
                  <span v-html="textData.sourceAuthor ? textData.sourceAuthor : '无'"></span>
                  <span class="my-caozuo" @click="myCopy([textData.source, textData.sourceAuthor], 1)">
                    <img src="../assets/img/views/hfz.png" alt />
                  </span>
                </div>
                <div class="left-country">
                  归属-阵营:
                  {{
            (textData.mediaOwnership ? textData.mediaOwnership : "无") +
            "-" +
            (textData.mediaCamp ? textData.mediaCamp : "无")
          }}
                  <span class="my-caozuo" @click="
          myCopy([textData.mediaOwnership, textData.mediaCamp], 2)
            ">
                    <img src="../assets/img/views/hfz.png" alt />
                  </span>
                </div>

                <div class="left-language" v-if="activeName == 'first'">
                  关键字:
                  <span v-if="textData.keyword">
                    <span v-for="(ite, indx) in textData.keywordList" :class="[
            ite !=
              textData.keywordList[textData.keywordList.length - 1]
              ? 'styleMargin'
              : '',
          ]" :key="indx">{{ ite }}</span>
                  </span>
                  <span v-else>无</span>
                  <el-tooltip v-if="textData.keyword && textData.keywordArry && textData.keywordArry.length > 10"
                    class="item" effect="dark" placement="top">
                    <div slot="content" v-html="textData.keyword"></div>
                    <span class="gengduo-link">(更多)</span>
                  </el-tooltip>
                  <span class="my-caozuo" @click="myCopy(textData.keyword, 3)">
                    <img src="../assets/img/views/hfz.png" alt />
                  </span>
                </div>
              </div>
              <div class="main-center-center">
                <div class="left-source">
                  媒体类型: {{ textData.mediaType ? textData.mediaType : "无" }}
                  <span class="my-caozuo" @click="myCopy(textData.mediaType, 4)">
                    <img src="../assets/img/views/hfz.png" alt />
                  </span>
                </div>
                <div v-if="activeName == 'first'" class="left-source" style="margin-top:26px">
                  语言类型: {{ textData.languageType ? textData.languageType : "无" }}
                  <span class="my-caozuo" @click="myCopy(textData.languageType, 6)">
                    <img src="../assets/img/views/hfz.png" alt />
                  </span>
                </div>
              </div>
              <div v-if="type != 'setting'" class="main-center-right">
                <div class="center-right-img" v-loading="loadingImg" element-loading-text="正在截图中">
                  <el-image style="width: 100px; height: 100px" :src="textData.fastPic" :preview-src-list="srcList">
                    <div slot="error" class="image-slot">
                      <img src="../assets/img/views/no.jpg" alt />
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
            <p v-html="item ? item : ''" class="main-text" v-for="(item, index) in textData.contentList"
              :key="index + 'a'"></p>
            <div>
              <p style="color: #333333" v-html="item ? item : ''" class="main-text"
                v-for="(item, index) in textData.contentTraslationList" :key="index + 'b'"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- 相似 列表 -->
      <div v-if="types != '首页监测' && activeName == 'first' && textData.isDel != 1 && activeNames != 'six'
            " class="information-bottom">
        <div class="information-bottom-box" v-loading="likeLoadFlag">
          <div class="bottom-xiangsi">
            <div style="margin-right: 5px">
              <span class="bottom-xiangsi-one">相似</span>
              <div class="bottom-line"></div>
            </div>
            <span style="font-weight: bold; color: #333333">({{ linkData ? linkData : 0 }}条)</span>
            <div v-if="gengduodata.length != 0" @click="changeData" class="change-title">
              <i class="el-icon-refresh"></i>换一换
            </div>
          </div>
          <!-- 推荐 -->
          <div class="bottom-count">
            <div @click="pushClick(item)" class="bottom-count-left" v-for="(item, index) in gengduodata" :key="index">
              <div class="count-left-coution">
                <div :class="[
            'type',
            'type1',
            item.tonalState == 2
              ? 'red'
              : item.tonalState == 1
                ? 'neutral'
                : 'obverse',
          ]">
                  {{
            item.tonalState == 2
              ? "负面"
              : item.tonalState == 0
                ? "正面"
                : "中性"
          }}
                </div>
                <!-- 网站 -->
                <div v-if="item.mediaLink != null" class="type left-website">{{ item.mediaLink }}</div>
                <!-- 是否预警 -->
                <div v-if="item.isEarlyWarn == 1" class="left-yujing">已预警</div>
                <div class="left-text">{{ item.title }}</div>
              </div>

              <div class="bottom-text-box">
                <div class="bottom-text-caozuo">
                  <span class="publics source">
                    媒体类型:
                    {{ item.mediaType ? item.mediaType : "无" }}
                  </span>
                  <span class="publics source">
                    媒体归属:
                    {{ item.mediaOwnership ? item.mediaOwnership : "无" }}
                  </span>
                  <span class="publics source">
                    媒体阵营:
                    {{ item.mediaCamp ? item.mediaCamp : "无" }}
                  </span>
                  <span class="publics bottom-gengduo">
                    来源/作者:
                    {{
            (item.source ? item.source : "无") +
            "/" +
            (item.sourceAuthor ? item.sourceAuthor : "无")
          }}
                  </span>
                  <span v-if="item.keyword && activeName == 'first'" class="publics bottom-key">
                    关键字：
                    <span v-for="(ite, indx) in item.keywordList" :class="[
            ite != item.keywordList[item.keywordList.length - 1]
              ? 'styleMargin'
              : '',
          ]" :key="indx">{{ ite }}</span>
                    <el-tooltip v-if="item.keyword && item.keywordArry.length > 3" class="item" effect="dark"
                      placement="top">
                      <div slot="content" v-html="item.keyword"></div>
                      <span class="gengduo-link">(更多)</span>
                    </el-tooltip>
                  </span>
                  <span v-if="!item.keyword && activeName == 'first'" class="publics bottom-key">
                    关键字：
                    <span>无</span>
                  </span>
                </div>
                <span v-if="item.releaseTime != null" class="publics bottom-time">{{ item.releaseTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import deleteDialog from "../components/deleteDialog";
import {
  getAbroadInfo,
  newgetInfo,
  editLabel,
  deleteList,
  likeList,
  deleteLabel,
  translate,
  message
} from "../api/abroad/list";
import { setLabel, screenShot, screenShotState } from "../api/accurate/list";
import { dataReport } from "../api/message/list";
import { monitorIndex } from "../api/index/index";
export default {
  data() {
    return {
      // 图片的loading
      loadingImg: false,
      srcList: [],
      img: "",
      loading: true,
      activeName: "",
      type: "",
      dataId: "",
      count: 560,
      // 设置标签
      biaoqianList: [],
      textData: {},
      gengduodata: [],
      linkData: 0,
      likeLoadFlag: false,//加载相似数据
      pageNum: 1,
      activeNames: sessionStorage.getItem("activeName"),
      websocket: null,
      stop: null,
      languages: {},
    };
  },
  props: {
    types: {
      type: String
    },
    dataIds: {
      type: String
    },
    montype: {
      type: Number
    },
    cityId: {
      type: Number
    }
  },
  components: {
    deleteDialog
  },
  methods: {
    // 修改标签
    async changeLabel(val) {
      if (val.setLabel == -1) {
        const res = await deleteLabel({
          dataId: val.dataId,
          organId: this.$store.state.user.organizationId
        });
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功,已取消标签！",
            type: "success"
          });
        } else {
          this.$message.error("操作失败，请稍后再试!");
        }
        if (this.types == "首页监测") {
          this.monitorList();
        } else {
          this.getList();
        }
        return;
      }
      let res = await editLabel({
        dataId: val.dataId,
        organId: this.$store.state.user.organizationId,
        setLabel: val.setLabel
      });
      if (res.data.code == 200) {
        this.$message({
          message: "修改标签成功！",
          type: "success"
        });
      } else {
        this.$message.error("修改标签失败！");
      }
      if (this.types == "首页监测") {
        this.monitorList();
      } else {
        this.getList();
      }
    },
    pushClick(item) {
      let routeUrl = this.$router.resolve({
        path: "/sensitiveMessage/index/xiangqing",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.dataId,
              type: "境外",
              activeName: this.activeName
            })
          )
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    pushClick2() {
      if (this.type == "境外") {
        this.$router.push({
          path: "/sensitiveMessage/index"
        });
      } else if (this.types == "首页监测" || this.type == "yujing") {
        this.$router.push({
          path: "/index"
        });
      } else {
        this.$router.push({
          path: "/systemSettings/index"
        });
      }
    },
    // 获取相似列表
    async likeList() {
      let data = {
        organId: this.$store.state.user.organizationId,
        pageNum: this.pageNum,
        pageSize: 5
      };
      if (this.types == "首页监测") {
        data.dataId = this.dataIds;
      } else {
        data.dataId = this.dataId;
      }
      this.likeLoadFlag = true;
      const res = await likeList(data);
      if (res != undefined) {
        if (res.data.data) {
          if (res.data.data.rows.length == 0 && this.pageNum == 1) {
            this.likeLoadFlag = false;
            return false;
          }
          if (res.data.data.rows.length == 0) {
            this.pageNum = 0;
            this.changeData();
          } else {
            this.gengduodata = res.data.data.rows;
            this.linkData = res.data.data.total;
            this.gengduodata.forEach(item => {
              // item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
              if (item.keyword) {
                item.keyword = item.keyword.replace(/<br\/>/g, " ");
                item.keywordList = item.keyword.split(" ");
                item.keywordArry = item.keyword.split(" ");
                if (item.keywordList.length > 3) {
                  item.keywordList = item.keywordList.slice(0, 3);
                }
                let reg = new RegExp(" ", "g"); //g代表全部
                item.keyword = item.keyword.replace(reg, "<br/>");
              }
            });
            this.likeLoadFlag = false;
          }
        }
      }
    },
    changeData() {
      this.pageNum++;
      this.likeList();
    },
    // 删除境外信息
    async deleteFn(item) {
      const res = await deleteList({
        dataId: item.dataId,
        organId: this.$store.state.user.organizationId
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success"
        });
      } else {
        this.$message.error("删除失败！");
      }
      setTimeout(() => {
        this.$router.push("/sensitiveMessage/index");
      }, 1000);
    },
    // 所有复制
    copy(msg) {
      this.$publicFun.customCopy(msg);
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
    },
    myCopy(msg, type) {
      let str = "";
      if (type == 2) {
        msg = msg.map(item => {
          return item ? item : "无";
        });
        str = `归属-阵营:${msg}`;
      }
      if (type == 1) {
        msg = msg.map(item => {
          return item ? item : "无";
        });
        str = `来源/作者:${msg}`;
      }
      msg = msg ? msg : "无";
      if (type == 3) {
        str = `关键字:${msg}`;
      } else if (type == 4) {
        str = `媒体类型:${msg}`;
      } else if (type == 5) {
        str = `舆情标签:${this.label}`;
      } else if (type == 6) {
        str = `语言类型:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    // 页面截图
    async screenShot(item) {
      this.srcList = [];
      this.loadingImg = true;
      await screenShot({
        typeStr: "OVERSEAS_DATA",
        infoId: item.dataId,
        orgaId: this.$store.state.user.organizationId,
        uuid: item.screenshotAddress,
        website: item.website
      });
      // if (res.data.code == 200) {
      //   this.$message({
      //     message: res.data.data.ResponseMsg,
      //     type: "success"
      //   });
      //   this.screenShotState();
      // }
    }, // 查看是否截图状态
    async screenShotState() {
      const res = await screenShotState({
        infoId: this.textData.dataId,
        orgaId: this.$store.state.user.organizationId,
        typeStr: "OVERSEAS_DATA",
        uuid: this.textData.screenshotAddress
      });
      if (res.data.code == 200 && res.data.data.type == -1) {
        return false;
      }
      if (res.data.code == 200 && res.data.data.type == 0) {
        this.textData.disabled = true;
        this.loadingImg = true;
        // setTimeout(() => {
        //   this.screenShotState();
        // }, 60000);
      } else if (res.data.code == 200 && res.data.data.type == 1) {
        this.$message.success("截图成功！");
        this.loadingImg = false;
        this.textData.disabled = false;
        setTimeout(() => {
          if (this.types == "首页监测") {
            this.monitorList();
          } else {
            this.getList(1);
          }
        }, 1000);
      }
    },
    //收藏/取消
    async collect(item) {
      let res = await editLabel({
        dataId: item.dataId,
        organId: this.$store.state.user.organizationId,
        isCollect: item.isCollect === 0 ? 1 : 0
      });
      if (res != undefined) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        item.isCollect = item.isCollect === 0 ? 1 : 0;
      }
    },
    //数据上报 //首页境外跳进去没有
    async dataReport(item) {
      if (item.isReport === 1) {
        this.$message({
          message: "请勿重复上报!",
          type: "warning"
        });
        return false;
      }
      let data = {
        infoType: 2,
        inforMsgId: item.dataId,
        organizationId: this.$store.state.user.organizationId
      };
      const res = await dataReport(data);
      if (res != undefined) {
        if (res.data.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: "warning"
          });
        } else {
          this.$message({
            message: res.data.data.msg,
            type: "success"
          });
        }
      }
      if (this.types == "首页监测") {
        this.monitorList();
      } else {
        this.getList();
      }
    },
    // 删除
    deletes(item) {
      item.message = "这篇境外新闻";
      this.$refs.openDialog.openDialog(item);
    },
    // 获取列表
    async getList(type) {
      this.loading = true;
      let res;
      if (this.activeName == "first") {
        res = await getAbroadInfo({
          dataId: this.dataId,
          organId: this.$store.state.user.organizationId
        });
      } else {
        res = await newgetInfo({
          dataId: this.dataId
        });
      }
      if (res.data.data) {
        if (res.data.data.titleTraslation) {
          res.data.data.titleTraslation =
            "译文:" + res.data.data.titleTraslation;
        } else {
          res.data.data.titleTraslation = "译文：无";
        }

        this.textData = res.data.data;

        if (this.textData.keyword) {
          this.textData.keyword = this.textData.keyword.replace(/<br\/>/g, " ");
          this.textData.keywordList = this.textData.keyword.split(" ");
          this.textData.keywordArry = this.textData.keyword.split(" ");
          if (this.textData.keywordList.length > 10) {
            this.textData.keywordList = this.textData.keywordList.slice(0, 10);
          }
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
        }
        if (this.textData.content) {
          this.textData.contentList = this.textData.content.split("\n");
        }
        if (this.textData.contentTraslation) {
          this.textData.contentTraslationList = this.textData.contentTraslation.split(
            "\n"
          );
        }
        if (this.textData.fastPic) {
          this.srcList.push(this.textData.fastPic);
        } else if (!type && !this.textData.fastPic) {
          this.screenShotState();
        }
      }
      let arrList = [];
      this.biaoqianList.forEach(item => {
        arrList.push(item.labelId);
      });
      if (arrList.indexOf(this.textData.setLabel) == -1) {
        this.textData.setLabel = null;
      }
      this.loading = false;
    },
    //获取标签
    async setLabel() {
      const res = await setLabel({
        organizationId: this.$store.state.user.organizationId,
        noShow: 1
      });
      this.biaoqianList = res.data.rows;
      if (this.types == "首页监测") {
        this.monitorList();
      } else {
        this.getList();
        if (this.activeName == "first") {
          this.likeList();
        }
      }
    },
    async monitorList() {
      let data = {
        dataId: this.dataIds,
        montype: this.montype,
        monitorId: this.cityId
      };
      const res = await monitorIndex(data);
      res.data.data.titleTraslation = "译文:" + res.data.data.titleTraslation;
      this.textData = res.data.data.map;

      if (this.textData.keyword) {
        this.textData.keyword = this.textData.keyword.replace(/<br\/>/g, " ");
        this.textData.keywordList = this.textData.keyword.split(" ");
        this.textData.keywordArry = this.textData.keyword.split(" ");
        if (this.textData.keywordList.length > 10) {
          this.textData.keywordList = this.textData.keywordList.slice(0, 10);
        }
        let reg = new RegExp(" ", "g"); //g代表全部
        this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
      }
      if (this.textData.content) {
        this.textData.contentList = this.textData.content.split("\n");
      }
      if (this.textData.contentTraslation) {
        this.textData.contentTraslationList = this.textData.contentTraslation.split(
          "\n"
        );
      }
      if (this.textData.fastPic) {
        this.srcList.push(this.textData.fastPic);
      }
      let arrList = [];
      this.biaoqianList.forEach(item => {
        arrList.push(item.labelId);
      });
      if (arrList.indexOf(this.textData.setLabel) == -1) {
        this.textData.setLabel = null;
      }
      this.loading = false;
    },
    websocketLink() {
      let that = this;
      this.websocket = new WebSocket(this.$store.state.websocket + sessionStorage.getItem("token") + "fastpic" + this.$store.state.user.organizationId + this.dataId + "1");
      this.websocket.onerror = function () {
        setTimeout(() => {
          this.screenShotState();
        }, 30000);
      }
      this.websocket.onmessage = function (res) {
        let data = JSON.parse(res.data);
        if (data.flag == 2 && data.dataId == that.dataId && data.organId == that.$store.state.user.organizationId) {
          that.$message.success("截图成功！");
          that.loadingImg = false;
          that.textData.disabled = false;
          setTimeout(() => {
            that.getList(1);
          }, 1000);
        }
      }
      window.onbeforeunload = function () {
        that.websocket.close();
      }
      this.websocket.onclose = function () {
        clearInterval(that.stop);
      }
      this.stop = setInterval(() => {
        this.websocket.send(
          JSON.stringify({
            heartCheck: "heartCheck",
            token: sessionStorage.getItem("token") + "fastpic" + this.$store.state.user.organizationId + this.dataId + "1",
          }));
      }, 10000);
    },
    translateYes(data) {
      let obj = {
        "title": data.title,
        "content": data.content
      }
      translate(obj).then(res => {
        if (res.data.code == 200 && res.data.data.content) {
          this.textData.contentList = [res.data.data.content];
          this.textData.titleF = "译文：" + res.data.data.title.replace(/<.+?>/g, " ");
          this.$forceUpdate();
        }
      })
    }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.activeName = params.activeName;
      this.type = params.type;
      this.dataId = params.id * 1;
    }
    this.setLabel();
    this.websocketLink();
    message().then(res => {
      if (res.data.code == 200) {
        this.languages = res.data.data;
      }
    });
  },
  beforeDestroy() {
    this.websocket.close();
  }
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.styleMargin {
  margin-right: 5px;
}

.gengduo-link {
  font-size: 14px;
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.main-top-center-box {
  display: flex;
  align-items: center;
}

.main-top-center-box .yujing {
  margin-left: 0;
  width: 62px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
}

.main-top-center-box .yujing-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-top-center-box div:nth-child(2) p {
  width: 100%;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.comment-weidu {
  color: #ea3342;
}

.information-box {
  padding: 0 24px 16px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../assets/img/views/beijing.png");
}

.center-title {
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.type {
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 8px;
}

.left-website {
  margin-left: 8px;
  background-color: #868bff;
}

.website {
  margin-left: 16px;
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  font-size: 14px;
}

.original-color {
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}

.center-bottom {
  height: 35px;
  margin-top: 16px;
}

.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}

.center-key {
  font-size: 14px;
  color: #999999;
}

.main-text {
  margin-top: 24px;
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
  word-break: break-all;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}

.operation-box img {
  width: 16px;
  height: 16px;
}

.operation-box {
  margin-top: 18px;
  display: flex;
  width: 1137px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  align-items: center;
}

.operation-box>div:not(:first-child) {
  margin-left: 40px;
}

.operation-box>div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.operation-box>div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.pinglun-num {
  cursor: pointer;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e59ec;
  position: absolute;
  right: 0px;
}

.main-center {
  padding: 24px 24px 24px 32px;
  display: flex;
  width: 100%;
  height: 156px;
  background: #f4f8fd;
}

.main-center>div {
  font-size: 14px;
  color: #333333;
  /* flex: 1; */
}

.main-center-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.main-center-center {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.left-source,
.left-country {
  display: flex;
  align-items: center;
}

.left-language {
  display: flex;
}

.left-language span,
.left-source span,
.left-country span {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.operation-copy {
  margin: 0;
}

.center-right-img {
  width: 111px;
  height: 108px;
}

.center-right-img img {
  width: 111px;
  height: 108px;
}

.main-center-right {
  height: 110px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.information-bottom {
  padding: 0px 24px 24px 24px;
}

.information-bottom-box {
  padding: 40px 240px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}

.bottom-xiangsi-one {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.bottom-xiangsi {
  align-items: center;
  display: flex;
}

.bottom-line {
  width: 42px;
  height: 6px;
  background: linear-gradient(90deg, #4ed2ff 0%, #0d8bff 98%);
}

.bottom-count {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}

.bottom-count-left {
  width: 100%;
  margin-top: 32px;
}

.left-yujing {
  padding: 0 8px;
  margin-left: 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
  line-height: 24px;
}

.left-text {
  margin-left: 16px;
  width: 576px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}

.count-left-coution {
  display: flex;
}

.left-time {
  font-size: 14px;
  color: #333333;
}

.english {
  background-color: #3e82ff;
}

.yidu {
  color: #02bc7c;
}

.yishoucang {
  color: #ff9000;
}

.bottom-text-box {
  padding-right: 17px;
  display: flex;
  justify-content: space-between;
}

.bottom-text-caozuo>span:not(:first-child) {
  margin-left: 40px;
}

.bottom-text-caozuo {
  margin-top: 16px;
}

.bottom-meiti {
  font-size: 14px;
  color: #b8bcc3;
}

.publics {
  font-size: 14px;
  color: #b8bcc3;
  margin-top: 16px;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.change-title {
  color: #626675;
  float: right;
  margin-left: 20px;
  cursor: pointer;
}

.delete-wrap {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  height: 700px;
  padding-top: 208px;
}

.delete-div {
  text-align: center;
}

.delete-div>p {
  font-size: 14px;
  color: #999999;
}

.delete-img {
  position: relative;
  width: 368px;
  margin: auto auto;
}

.delete-x {
  font-size: 70px;
  color: #ffb2b2;
  position: absolute;
  bottom: 10px;
  right: 120px;
  z-index: 22;
}

.translateDiv {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}
</style>
